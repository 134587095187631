.wrapper {
  user-select: none;
  width: 100vw;
}

.column {
  width: calc(100vw - 72px);
  // max-width: 100%;
  display: flex;
  background-color: #383d48;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  letter-spacing: -0.35px;
  color: #9ea7ad;
  padding: 0px 36px;
  &-num {
    width: 48px;
    text-align: center;
  }
  &-img {
    width: 88px;
    text-align: center;
  }
  &-title {
    width: calc(100vw - 952px);
    padding-left: 24px;
  }
  &-date {
    width: 116px;
    padding-left: 24px;
  }
  &-type {
    width: 116px;
    padding-left: 24px;
  }
  &-size {
    width: 116px;
    padding-left: 24px;
  }
  &-alpha {
    width: 76px;
    padding-left: 24px;
  }
  &-link {
    width: 76px;
    padding-left: 24px;
  }
  &-copy {
    width: 76px;
    padding-left: 24px;
  }
  > div:not(:first-child) {
    position: relative;
    // box-sizing: border-box;
  }
  > div:not(:first-child):before {
    content: "";
    width: 1px;
    height: 12px;
    background-color: #555963;
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

.listdata {
  // width: 100%;
  max-width: 100%;
  // height: 100px;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: #555963;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.35px;
  color: #9ea7ad;
  height: calc(100vh - 223px);
  // padding: 0px 36px;

  &-wrapper {
    display: flex;
    width: calc(100vw - 72px);
    height: 52px;
    overflow: auto;
    align-items: center;
    padding: 0 36px;
    &:hover {
      background-color: #4a4e58;
    }
  }

  &-wrapper.selected {
    background-color: #414650;
  }

  &-num {
    width: 48px;
    text-align: center;
  }
  &-img {
    width: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > img {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 4px;
    }
    &-hover {
      display: none;
    }
    &:hover &-hover {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 36px;
      height: 36px;
      background: rgba(13, 16, 26, 0.8);
      border-radius: 4px;
      left: 26px;
      top: 0;

      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.3px;
      color: #ffffff;
    }
  }
  &-title {
    width: calc(100vw - 952px);
    padding-left: 24px;
    text-overflow: ellipsis;
    color: #ffffff;
    span {
      text-overflow: ellipsis;
    }
  }
  &-date {
    width: 116px;
    padding-left: 24px;
  }
  &-type {
    width: 116px;
    padding-left: 24px;
  }
  &-size {
    width: 116px;
    padding-left: 24px;
  }
  &-alpha {
    width: 76px;
    padding-left: 24px;
  }
  &-link {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #5c6067;
        border-radius: 4px;
      }
      > svg {
        fill: #9ea3aa;
        &:hover {
          fill: #ffffff;
        }
      }
    }
  }
  &-copy {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #9ea3aa;
      &:hover {
        background-color: #5c6067;
        border-radius: 4px;
        color: #ffffff;
      }
    }
  }
}
