.background {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  > img {
    border-radius: 8px;
  }
}

.magnifier {
  position: absolute;
  opacity: 1;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  pointer-events: none;
}
