/** @format */

.preview {
  font-family: "Apple SD Gothic Neo";
  position: absolute;
  // display: none;
  background-color: rgba(0, 0, 0, 0.8);
  // width: 100%;
  // height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 36px;
  div {
    font-family: "Apple SD Gothic Neo";
    // height: 80%;
  }
  &-container {
    width: 100%;
    height: 100%;
    background: #ffffff;
  }
  &-logo {
    width: 90%;
    height: 25px;
    background: url("../../images/login/login-logo.svg") no-repeat;
    background-size: contain;
    margin: 27px 40px;
  }
  &-content {
    height: calc(100% - 105px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  &-title {
    width: 305px;
    height: 104px;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 28px;
    line-height: 134%;
    letter-spacing: -0.01em;
  }
  &-form {
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    // & > div:hover .login-icon {
    // 	display: block;
    // }
  }
  .error-message {
    color: #ef284c;
    font-size: 12px;
    margin-top: 8px;
    width: 100%;
    margin-left: 0;
    align-items: flex-start;
    font-family: Apple SD Gothic Neo;
    letter-spacing: -0.3px;
    font-weight: normal;
  }
  input {
    width: 400px;
    height: 48px;
    padding-left: 16px;
    background: #f0f1f2;
    border: 0;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 12px;
    color: #303538;
    font-weight: bold;
    &::placeholder {
      color: #9ea3aa;
      font-size: 16px;
      font-family: Poppins;
      font-weight: 400;
    }
  }
  &-action {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
    color: #79828a;
    font-size: 14px;
    a {
      cursor: pointer;
      width: 194px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00b48e;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }
    a:first-child {
      margin-right: 12px;
      background: #e6e8eb;
      color: #383d48;
    }
    .line {
      color: #e8e8e8;
    }
  }
}

.window {
  width: calc(100vw - 72px);
  height: calc(100vh - 72px);
  border-radius: 16px;
}
.preview-header {
  display: flex;
  height: 60px;
  width: calc(100% - 72px);
  background-color: #252a35;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 0 36px;

  & > div {
    display: flex;
    flex: 1 1;
    // border-radius: 16px;
  }
  &-left {
    justify-content: flex-start;
    &-button {
      background-color: #383d48;
      margin-left: 15px;
      font-size: 20px;
      width: 35px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border: 1px solid #9ea2a8;
      border-radius: 4px;
      color: #ffffff;
      &:hover {
        background-color: #0bcea5;
        border: 1px solid #ffffff;
        color: #ffffff;
        cursor: pointer;
      }
      user-select: none;
    }
  }
  &-center {
    justify-content: center;
    font-size: 16px;
    color: #e6e8eb;
  }
  &-right {
    justify-content: flex-end;
    &-button {
      background: #383d48;
      border-radius: 4px;
      width: 68px;
      height: 32px;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      &:hover {
        background-color: #464a53;
        color: #ffffff;
      }
    }
  }
}

.screen {
  border-radius: 16px;
}

.screen-containers {
  display: flex;
  width: 100%;
  // overflow-x: scroll;
  // overflow-y: scroll;
  height: calc(100vh - 192px);
  // align-items: center;
}
.screen-center-container-preview {
  display: flex;

  width: calc(100% - 72px);
  padding: 0 36px;
  // overflow-x: scroll;
  // overflow-y: scroll;
  // height: 500px;
  // height: 100%;
  // flex-wrap: wrap;
  // align-content: flex-start;
  background-color: #545861;
}

.screen-bottombar2 {
  position: relative;
  width: 100%;
  height: 60px;
}

.PreviewStage {
  z-index: 100;
  &:focus {
    outline: none;
  }
  width: 100%;
  height: 100%;
  cursor: grab;
  // overflow-x: scroll;
  // overflow-y: scroll;
  margin-bottom: 0;
  padding-bottom: 0;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.PreviewStage::-webkit-scrollbar {
  background: transparent;
  width: 10px;
  height: 8px;
}

.PreviewStage::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}
.PreviewStage::-webkit-scrollbar-thumb:hover {
  background: #737780;
}

.bottombar2 {
  // width: 100%;
  height: 60px;
  background-color: #252a35;
  display: flex;
  align-items: center;
  padding: 0 50px;
  justify-content: space-between;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  &-left {
    // width: 100%;
    height: 48px;
    background-color: #252a35;
    display: flex;
    align-items: center;
    &-container.minus {
      height: 24px;
      margin-right: 12px;
    }

    &-container.scale {
      color: #f6f7f8;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-right: 12px;
    }
    &-container.plus {
      height: 24px;
      border-right: 1px solid #383d48;
      padding-right: 8px;
    }

    &-container.reset {
      height: 24px;
      padding: 0 8px;
      border-right: 1px solid #383d48;
    }

    &-container.grab {
      height: 24px;
      padding: 0 8px;
      border-right: 1px solid #383d48;
    }

    &-container.rotate {
      padding: 0 8px;
      height: 24px;
    }
  }
  &-right {
    > button {
      width: 96px;
      height: 32px;
      background: #00b48e;
      border-radius: 4px;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.4px;
      color: #ffffff;
      &:hover {
        background: #0bcea5;
      }
    }
  }
}
