/** @format */

.loading {
  background-color: #555963;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // opacity: 0.1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  path {
    fill: #0bcea5;
    stroke: #0bcea5;
  }
  .dots {
    background-color: #0bcea5;
    animation: dots 5s 0s infinite;
    width: 100%;
    height: 100%;
  }
  .loading-img {
    margin-top: 32px;
    width: 80px;
    height: 80px;
    background: #b3b8c0;
    border-radius: 18px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    position: relative;
  }
  .exportImg {
    position: absolute;
    top: 0;
    width: 80px;
    height: 80px;
    background: url("../../images/pages/export.svg") no-repeat;
    background-size: contain;
  }
  &-logo {
    margin-top: 25px;
    width: 180px;
    height: 27px;
    background: url("../../images/main/loadding-logo.svg") no-repeat;
    background-size: contain;
  }
}

.detectLoading {
  position: absolute;
  background: rgba(13, 16, 26, 0.8);
  border-radius: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  // opacity: 0.1;
  // position: fixed;
  // top: 0;
  // left: 0;
  path {
    fill: #0bcea5;
    stroke: #0bcea5;
  }
  .dots {
    background-color: #0bcea5;
    animation: dots 5s 0s infinite;
    width: 100%;
    height: 100%;
  }
  &-img {
    margin-top: 32px;
    width: 80px;
    height: 80px;
    background: #b3b8c0;
    border-radius: 18px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    position: relative;
  }
  .exportImg {
    position: absolute;
    top: 0;
    width: 80px;
    height: 80px;
    background: url("../../images/pages/export.svg") no-repeat;
    background-size: contain;
  }
  &-logo {
    margin-top: 25px;
    width: 180px;
    height: 27px;
    background: url("../../images/main/loadding-logo.svg") no-repeat;
    background-size: contain;
  }
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
input[type="checkbox"]:checked + label {
  background-color: #111;
}
select {
  width: 120px;
  height: 40px;
  padding: 0.8em 0.8em;
  border: 1px solid #eee;
  font-family: inherit;
  background: url("../../images/pages/arrow.png") no-repeat 95% 50%;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}
[z-popup="my-menu"] {
  font-family: "Apple SD Gothic Neo";
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  // width: 372px;
  // height: 498px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.modal-shadow {
  font-family: "Apple SD Gothic Neo";
  position: fixed;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .modal-file {
    width: 420px;
    min-height: 122px;
    background-color: #fff;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Apple SD Gothic Neo";

    &-title {
      padding: 24px;
      color: #373c47;
      font-weight: bold;
    }
    &-input {
      display: flex;
      align-items: center;
      padding: 6px 24px;
      position: relative;
      margin-top: 6px;
      &-text {
        width: 300px;
        height: 44px;
        background: #f6f6f6;
        border: 1px solid #eeeeee;
        padding-left: 16px;
        color: #9ea2a8;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
        position: absolute;
      }
      &-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        width: 88px;
        height: 48px;
        background: #111111;
        border-radius: 5px;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        right: 24px;
      }
    }
  }
}
.home-move {
  color: #9ea3aa;
  font-size: 30px;
  // line-height: 40px;
  font-family: Poppins;
  position: absolute;
  top: 84px;
  left: 130px;
  display: flex;
  // align-items: center;
  cursor: pointer;
  .arrow-left {
    width: 16px;
    height: 27px;
    background: url("../../images/pages/arrow-left.svg") no-repeat;
    background-size: contain;
    margin-right: 34px;
  }
}
.modal {
  width: 320px;
  min-height: 192px;
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &-background {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #373c47;
    padding-top: 38px;
    padding-bottom: 16px;
    font-family: "Apple SD Gothic Neo";
  }
  &-content {
    width: 261px;
    font-size: 14px;
    color: #9ea3aa;
    margin-bottom: 36px;
    padding: 0 20px;
    font-family: "Apple SD Gothic Neo";
    text-align: center;
    line-height: 20px;
  }
  &-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    div {
      height: 56px;
      text-align: center;
      line-height: 56px;
      cursor: pointer;
      font-family: Apple SD Gothic Neo;
    }
    &-close {
      width: 50%;
      color: #fff;
      background: #737780;
      font-weight: bold;
      border-radius: 0px 0px 0px 10px;
    }
    &-confirm {
      width: 100%;
      color: #ffffff;
      background: #111111;
      font-weight: bold;
      border-radius: 0px 0px 10px 0px;
    }
    &-export {
      width: 100%;
      color: #fff;
      background: #111111;
      border-radius: 0px 0px 10px 10px;
      font-weight: 700;
    }
  }
}
.modal-export-shadow {
  font-family: "Apple SD Gothic Neo";
  position: fixed;
  display: none;
  background-color: #555963;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .modal-export {
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 320px;
    .modal-img {
      margin-top: 32px;
      width: 48px;
      height: 48px;
      background: #b3b8c0;
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      position: relative;
      .dots {
        background-color: #0bcea5;
        animation: dots 10s 0s infinite;
        width: 100%;
        height: 0%;
      }
      .exportImg {
        width: 48px;
        height: 48px;
        background: url("../../images/pages/export.svg") no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
      }
    }
    .modal-title {
      font-size: 16px;
      color: #555963;
      padding-top: 16px;
      padding-bottom: 36px;
    }
    .modal-content {
      color: #9ea3aa;
      font-size: 14px;
      margin-bottom: 32px;
    }
    .modal-background {
    }
  }
}
@keyframes dots {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
.modal-save {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: 392px;
  height: 181px;
  background-color: #fff;
  border-radius: 20px;
  font-size: 14px;
  font-family: "Apple SD Gothic Neo";
  &-grid {
    width: calc(100% - 72px);
    height: calc(100% - 72px);
    padding: 36px;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    &-name {
      width: 100%;
      height: 24px;
      // padding: 32px 0 8px 20px;
      // padding: 0 36px;
      font-weight: bold;
      color: #545861;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
      > div > div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.3px;
        color: #9ea3aa;
        > img {
          margin-left: 12px;
        }
      }
    }
    &-progressbar {
      margin-bottom: 6px;
      // background-color: #0bcea5;
    }
    &-description {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: -0.3px;
      color: #9ea3aa;
    }
    &-input {
      width: 100%;
      display: flex;
      justify-content: center;
      input {
        width: 100%;
        height: 40px;
        background: #f6f7f8;
        border: 0;
        padding-left: 16px;
        box-sizing: border-box;
        margin: 0 20px;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }
    &-page {
      width: 100%;
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .checkbox-label {
        width: 55px;
      }
      .selectionRange {
        width: 156px;
        height: 40px;
        // background: #f6f6f6;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        padding-left: 16px;
        color: #9ea2a8;
        border-radius: 5px;
        margin-right: 20px;
      }
    }
    &-half {
      padding: 0 20px;
      &:last-child {
        padding-left: 0;
      }
      .modal-save-grid-name {
        padding-left: 0;
      }
      select {
        //margin-left: 20px;
      }
      .input-save {
        width: 192px;
        height: 40px;
        // background: #f6f6f6;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 5px;
        color: #9ea2a8;
        padding-left: 16px;
      }
    }
    .modal-save-route {
      display: flex;
      padding: 0 20px;
      &-input {
        width: 232px;
        height: 40px;
        padding-left: 16px;
        background: #f6f6f6;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 5px 0 0 5px;
        color: #9ea2a8;
      }
      &-input-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 40px;
        background: #545861;
        border-radius: 5px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  &-confirm {
    display: flex;
    justify-content: right;
    width: 100%;
    background-color: black;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    button {
      // margin: 1rem;
      padding: 1rem;
      width: 100%;
      color: white;

      // background-color: black;
    }
    // :hover {
    //   background-color: lightblue;
    // }
  }
  .total-page {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    margin-bottom: 32px;
    &-title {
      color: #737780;
      margin-right: 10px;
    }
    &-number {
      color: #373c47;
      font-weight: bold;
      margin-right: 22px;
      font-family: Poppins;
    }
  }
  &-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    div {
      height: 56px;
      text-align: center;
      line-height: 56px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.MuiLinearProgress-colorPrimary {
  background-color: #0bcea5;
}

.emailForm {
  // max-width: 840px;
  margin: 0 auto;
  // margin-bottom: 150px;
  &-title {
    margin-top: 80px;
    > :first-child {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 150%;
      letter-spacing: -1.35px;
      color: #ffffff;
    }
    > :last-child {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.5px;
      color: #79828a;
      margin-top: 8px;
    }
  }
  .email-section {
    // margin-top: 80px;
    .email-subTitle {
      width: 100%;
      margin-bottom: 40px;
      padding-bottom: 10px;
      text-align: left;
      border-bottom: 2px solid #383d48;
      margin-top: 80px;
      h4 {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.6px;
        color: #ffffff;
      }
    }
    .email-input-list {
      .email-input-item {
        width: 100%;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        // display: flex;
        justify-content: space-between;
        align-items: center;
        label {
          font-family: "Apple SD Gothic Neo";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.4px;
          color: #9ea3aa;
          display: block;
          i {
            color: #00b48e;
          }
        }
        > textarea {
          width: 100%;
          box-sizing: border-box;
          margin-top: 8px;
          background-color: #383d48;
          border: none;
          &:focus-visible {
            outline: 1px solid #00b48e;
          }
        }
        > input {
          flex: 1;
          width: 100%;
          margin-top: 8px;
          box-sizing: border-box;
          padding: 13px 16px;
          background-color: #383d48;
          border-radius: 8px;
          border: none;
          color: #ffffff;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
          letter-spacing: -0.4px;
          &::placeholder {
            color: #737780;
          }
          &:focus {
            border: 1px solid #00b48e;
          }
          &:focus-visible {
            outline: 1px solid #00b48e;
          }
        }
        a {
          flex: 1;
          text-decoration: none;
          .download-btn {
            width: calc(100% - 32px);
            height: 26px;
            padding: 14px 16px;
            margin-bottom: 16px;
            background-color: rgba(0, 180, 142, 0.12);
            border-radius: 8px;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: -0.4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              color: #00b48e;
            }
            img {
              width: 24px;
              height: 24px;
            }
            &:hover {
              width: calc(100% - 34px);
              height: 24px;
              border: 1px solid #00b48e;
            }
          }
        }
        &.button {
          margin-top: 60px;
          justify-content: flex-end;
        }
        #content {
          overflow: auto;
          flex: 1;
          min-height: 364px;
          padding: 16px;
          resize: none;
          // background-color: ${({ theme }) => theme.colorTheme.inputBg};
          border-radius: 8px;
          // border: 1px solid ${({ theme }) => theme.colorTheme.inputBg};
          // color: ${({ theme }) => theme.colorTheme.black};
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
          letter-spacing: -0.4px;
          &::placeholder {
            // color: ${({ theme }) => theme.colorTheme.systemSemiLightGrey};
          }
          &:focus {
            border: 2px solid #00b48e;
          }
        }
        &.checkbox {
          // padding-left: 180px;
          // width: calc(100% - 180px);
          display: block;
          .email-terms {
            // width: calc(100% - 32px);
            height: 98px;
            padding: 16px;
            border-bottom: 1px solid #383d48;
            scrollbar-color: red, red;
            p {
              width: 100%;
              height: 100px;
              overflow-y: scroll;
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.5rem;
              letter-spacing: -0.35px;
              color: #737780;
              text-align: justify;
              scrollbar-color: red, red;
            }
          }
          .email-checkbox {
            padding: 16px;
            display: flex;
            .checkbox-wrap {
              margin-right: 12px;
              display: flex;
              align-items: center;
            }
            > .checkbox-text {
              width: 140px;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.5rem;
              letter-spacing: -0.4px;
              color: #f6f7f8;
              &.accent {
                margin-left: 4px;
                // color: ${({ theme }) => theme.colorTheme.primary};
              }
            }
          }
        }
      }
      .alert {
        display: block;
        margin: -16px 0px 12px 12px;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.3px;
        color: rgb(239, 40, 76);
      }
    }
  }
  @media screen and (max-width: 480px) {
    .email-section {
      margin-top: 80px;
      .email-subTitle {
        padding-bottom: 6px;
      }
      .email-input-list {
        .email-input-item {
          flex-direction: column;
          label {
            display: block;
            margin-bottom: 10px;
            width: 100%;
          }
          input {
            display: block;
            width: 100%;
          }
          a {
            width: 100%;
            .download-btn {
              &:hover {
                border: 2px solid;
                // ${({ theme }) => theme.colorTheme.lightPrimary};
              }
            }
          }
          &.button {
            margin-top: 60px;
            justify-content: flex-end;
          }
          #content {
            width: 100%;
            min-height: 364px;
          }
          &.checkbox {
            margin-top: 16px;
            padding-left: 0px;
            .email-terms {
              scrollbar-color: red yellow;
              width: 100%;
              height: 150px;
              padding: 0 0 16px;
              margin-top: 16px;
              border-bottom: 1px solid
                // ${({ theme }) => theme.colorTheme.systemDarkWhite};
                p {
                scrollbar-color: dark;
                height: 134px;
                padding-right: 10px;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.5rem;
                letter-spacing: -0.35px;
                // color: ${({ theme }) => theme.colorTheme.systemGrey};
                scrollbar-color: red yellow;
              }
              scrollbar-color: #ffffff;
            }
            .email-checkbox {
              width: 100%;
              background-color: #fff;
              .checkbox-wrap {
                margin-right: 12px;
              }
              .checkbox-text {
                display: inline;
              }
            }
          }
        }
        .alert {
          display: block;
          width: 100%;
          margin: -12px 16px 12px;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1rem;
          letter-spacing: -0.3px;
          // color: ${({ theme }) => theme.colorTheme.error};
        }
      }
    }
  }
}

.complete-backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(13, 16, 26, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .completeWrapper {
    width: 320px;
    &-content {
      position: relative;
      background: #ffffff;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 64px;
      padding-bottom: 36px;
      > img {
        position: absolute;
        top: -40px;
      }
      &-title {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.5px;
        color: #111111;
      }
      &-body {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.35px;
        color: #9ea3aa;
        margin-top: 8px;
      }
    }
    &-btn {
      background: #111111;
      border-radius: 0px 0px 12px 12px;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.4px;
      color: #ffffff;
      padding: 16px 0;
      cursor: pointer;
    }
  }
}
