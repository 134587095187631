/** @format */

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
// html,
// body,
// .App,
// #root {
//   height: 100%;
//   overflow: hidden;
// }

.header {
  display: flex;
  height: 48px;
  width: 100%;
  background-color: #252a35;
  align-items: center;

  & > div {
    display: flex;
    flex: 1 1;
  }
  &-left {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
    font-size: 14px;
    color: #e6e8eb;
  }
  &-right {
    justify-content: flex-end;
    padding-right: 8px;
  }
  &-btn {
    @include flex-center();
    width: 70px;
    height: 32px;

    background: #383d48;
    border-radius: 5px;
    font-size: 13px;
    color: #fff;
    margin-right: 8px;
  }
  .logo {
    width: 168px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    button {
      height: 24px;
    }
  }
  .header-button {
    background-color: #383d48;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    height: 32px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 8px;
    &:hover {
      background-color: #555963;
    }
  }
  .icon-logo {
    background: url("../../images/pages/logo.svg") no-repeat;
    background-size: cover;
    width: 101px;
    height: 24px;
  }
  .home {
    background: url("../../images/pages/home.svg") no-repeat;
    background-size: cover;
    width: 16px;
    height: 16px;
  }
}
.screen-container {
  display: flex;
}
.screen-right-sideBar {
  height: calc(100vh - 48px);
  max-width: 240px;
  min-width: 240px;
  background-color: #383d48;
  flex: 1 auto;
  &-title {
    height: 32px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    color: #9ea3aa;
    font-size: 12px;
  }
  &-content {
    height: 32px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    color: #e8ebed;
    font-size: 12px;
    input {
      background-color: transparent;
      border: 0;
      color: #fff;
      outline: 0;
    }
  }
  &-btn {
    display: flex;
    margin-top: 14px;
    margin-left: 16px;
    margin-bottom: 14px;
    width: 80px;
    height: 40px;
    border-radius: 5px;
    background: #545861;
  }
  .mybutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    // background: #545861;
    border-radius: 5px;

    .select-btn {
      background: url("../../images/pages/select-btn.svg") no-repeat;
      background-size: cover !important;
      width: 16px;
      height: 16px;
    }

    .square_draw_btn {
      background: url("../../images/pages/square_draw_btn.svg") no-repeat;
      background-size: cover !important;
      width: 16px;
      height: 16px;
    }
    &.selected {
      background-color: #0bcea5;
      .select-btn {
        background: url("../../images/pages/select-btn-click.svg") no-repeat;
      }
      .square_draw_btn {
        background: url("../../images/pages/square_draw_btn-click.svg")
          no-repeat;
      }
    }
  }
}
.sliderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    width: 48px;
    height: 24px;
    padding-left: 8px;
    font-size: 12px;
    margin-right: 10px;
  }
}
.sliderDiv {
  padding: 0px 20px 40px;
}
.screen-left-sideBar {
  width: 200px;
  height: calc(100vh - 48px);
  background-color: #373c47;
}
.screen-left-sideBar-scroll {
  overflow-y: auto;
  height: calc(100vh - 112px);
  justify-content: flex-start;
  margin-right: 4px;
}
.screen-left-sideBar-scroll::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}
.screen-left-sideBar-scroll::-webkit-scrollbar-thumb:hover {
  background: #737780;
}
.screen-left-sideBar-scroll::-webkit-scrollbar-thumb {
  background-color: #555963;
  border-radius: 10px;
}
// .screen-center-container {
//   display: flex;
//   flex-wrap: wrap;
//   align-content: flex-start;
//   width: calc(100vw - 240px);
//   // min-height: 100vh;
//   // max-height: 100%;
//   height: calc(100vh - 48px);
//   background-color: #545861;
// }
.default-setting-btn {
  width: 240px;
  height: 40px;
  border-top: 1px solid #545861;
  border-bottom: 1px solid #545861;
  font-weight: 700;
  font-size: 13px;
  color: #f0f1f2;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.setting-open-btn {
  border-radius: 5px;
  width: 24px;
  height: 24px;
  color: #e6e8eb;
  img {
    height: 24px;
  }
  &:hover {
    background: #555963;
  }
  &:active {
    background: #252a35;
  }
}
.registration {
  @include flex-center();
  background: #0bcea5;
  border-radius: 5px;
  width: 208px;
  height: 40px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  margin: 16px;
  &:disabled {
    background: #555963;
    color: #9ea3aa;
  }
}
.labCode-list::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}
.labCode-list::-webkit-scrollbar-thumb:hover {
  background: #737780;
}
.labCode-list::-webkit-scrollbar-thumb {
  background-color: #555963;
  border-radius: 10px;
}
.labCode-list {
  overflow-y: auto;
  height: calc(100vh - 340px);
  &-item {
    padding-left: 8px;
    height: 32px;
    font-size: 12px;
    color: #f0f1f2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    &:hover {
      background-color: #3e4554;
    }
    &:hover .labCode-list-item-icon {
      display: flex !important;
    }
    &-input {
      width: 140px;
      margin-right: 4px;
    }
    input {
      width: 100%;
      background-color: #383d48;
      border-radius: 5px;
      border: 0;
      color: #f0f1f2;
      height: 28px;
      padding: 0;
      padding-left: 8px;
      font-size: 12px;
      &:focus,
      &:focus-visible,
      &:hover {
        background-color: #252a35;
        border: 1px solid #3390ee;
      }
    }
    span {
      font-size: 12px;
    }
    &-icon {
      display: none;
      margin-right: 16px;
      img {
        height: 12px;
      }
      button {
        @include flex-center();
        border-radius: 5px;
        width: 24px;
        height: 24px;
        &:hover {
          background: #474f5f;
        }
        &:active {
          background-color: #0d101a;
        }
      }
    }
  }
  .labCode-list-item.select {
    background-color: #2e3543;
  }
  .trash {
    width: 10px;
    height: 12px;
    background: url("../../images/pages/trash.svg") no-repeat;
    background-size: contain;
  }
  .icon-link {
    width: 12px;
    height: 12px;
    background: url("../../images/pages/icon-link.svg") no-repeat;
    background-size: contain;
  }
}
.more {
  width: 24px;
  height: 24px;
  background: url("../../images/pages/more.svg") no-repeat;
  background-size: contain;
}
.setting {
  display: none;
  width: 240px;
  background: #383d48;
  font-family: Poppins;
  position: absolute;
  right: 240px;
  top: 230px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  z-index: 200;
  &-title {
    font-weight: bold;
    font-size: 13px;
    color: #f6f7f8;
    padding: 10px 10px 10px 16px;
    border-bottom: 1px solid #545861;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-content {
    padding: 0 4px 4px 16px;
    height: 377px;
    overflow-y: auto;
    margin: 4px 4px 4px 0;
  }
  .setting-content::-webkit-scrollbar {
    background: transparent;
    width: 4px;
  }
  .setting-content::-webkit-scrollbar-thumb:hover {
    background: #737780;
  }
  .setting-content::-webkit-scrollbar-thumb {
    background-color: #555963;
    border-radius: 10px;
  }
  &-text {
    font-weight: bold;
    font-size: 12px;
    color: #b3b8c0;
    padding: 16px 0 8px;
  }
  &-item {
    display: flex;
    align-items: center;
    input {
      height: 32px;
      color: #f0f1f2;
      padding-left: 5px;
      background-color: #383d48;
      border: 1px solid #555963;
      border-radius: 5px;
      width: 100%;
      box-sizing: border-box;
      &:focus {
        background-color: #252a35;
        border: 1px solid #3390ee;
      }
      &:focus-visible {
        background-color: #252a35;
        border: 1px solid #3390ee;
      }
    }
    .select {
      height: 32px;
      color: #f0f1f2;
      background-color: #383d48;
      border: 1px solid #555963;
      outline: 0;
      border-radius: 5px;
      width: 100%;
      cursor: pointer;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .select-btn {
        height: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
      }

      img {
        height: 8px;
      }
    }
    a {
      width: 20px;
      height: 20px;
      margin-left: 4px;
      img {
        width: 20px;
        height: 20px;
        border-radius: 5px;
      }
    }
  }
  .optionGroup {
    background: #e6e8eb;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    display: none;
    top: 0;
    left: 0;
    .option {
      width: 176px;
      height: 32px;
      background: #e6e8eb;
      font-family: Poppins;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #555963;
      padding-left: 16px;
      padding-right: 16px;
      cursor: pointer;
      &:hover {
        background: #d4dfeb;
      }
      img {
        width: 12px;
      }
    }
  }
  &-code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 10px;
    div {
      padding: 0;
    }
    input {
      width: 100px;
      height: 32px;
      padding-left: 10px;
      border: 1px solid #555963;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: #383d48;
      color: #fff;
      &:focus {
        background-color: #252a35;
        border: 1px solid #3390ee;
      }
      &:focus-visible {
        background-color: #252a35;
        border: 1px solid #3390ee;
      }
    }
  }
  &-value {
    display: flex;
    flex-wrap: wrap;
  }
  &-btn {
    @include flex-center();
    width: 100px;
    height: 32px;
    background: #555963;
    border-radius: 5px;
    color: #9ea3aa;
    font-size: 12px;
    margin-bottom: 8px;

    cursor: pointer;
  }
  &-btn:nth-child(2n-1) {
    margin-right: 8px;
  }
  .setting-btn.selected {
    background: #9ea3aa;
    color: #ffffff;
    font-weight: 700;
  }
  &-actions {
    border-top: 1px solid #545861;
  }
  &-reset {
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    background: #0bcea5;
    mix-blend-mode: normal;
    color: #fff;
    border-radius: 5px;
    margin-top: 16px;
    margin-bottom: 16px;

    cursor: pointer;
  }
  .setting-reset:disabled {
    color: #9ea3aa;
    background: #555963;
    cursor: default;
  }
  &-save {
    width: 100px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    background: #737780;
    mix-blend-mode: normal;
    color: #fff;
    border-radius: 5px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 8px;
  }
  .select-arrow {
    width: 10px;
    height: 8px;
    background: url("../../images/pages/select-arrow.svg") no-repeat;
    background-size: cover;
  }
  .close {
    width: 10px;
    height: 13px;
    background: url("../../images/pages/close.svg") no-repeat;
    background-size: contain;
  }
  .slurp {
    width: 20px;
    height: 20px;
    background: url("../../images/pages/slurp.svg") no-repeat;
    background-size: contain;
  }
  .url {
    width: 20px;
    height: 20px;
    background: url("../../images/pages/url.svg") no-repeat;
    background-size: contain;
  }
  .check {
    width: 12px;
    height: 10px;
    background: url("../../images/pages/check.svg") no-repeat;
    background-size: contain;
  }
}
.setting-apply {
  @include flex-center();
  position: absolute;
  bottom: 0;
  width: 240px;
  height: 96px;
  border-top: 1px solid #545861;
  &-btn {
    @include flex-center();
    width: 208px;
    height: 56px;
    background: #0bcea5;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}
.pdfImage {
  @include flex-center();
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.tooltip-btn {
  position: relative;
}
.tooltip-btn:hover .tooltip {
  display: flex;
}
.tooltip {
  position: absolute;
  right: 0;
  top: 32px;
  display: none;
  animation: tooltip 1s 0.6s;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 8px;
  font-size: 12px;
  font-family: Apple SD Gothic Neo;
  color: #f6f7f8;
  background: #111111;
  opacity: 0;
}
@keyframes tooltip {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.tooltip-right:after {
  border-top: 0px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #111111;
  content: "";
  position: absolute;
  top: -5px;
  right: 5px;
}
.tooltip-left:after {
  border-top: 0px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #111111;
  content: "";
  position: absolute;
  top: -5px;
  left: 5px;
}
.tooltip-center:after {
  border-top: 0px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #111111;
  content: "";
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
}
.serverImg {
  position: absolute;
  background: rgba(196, 196, 196, 0.4);
  img {
  }
}
.serverImg.select {
  border: 2px solid #0bcea5;
}
.InovuaReactDataGrid--theme-default-light {
  background: transparent !important;
}
/** @format */
a {
  color: #eee;
  text-decoration: none;
}
button {
  font-family: "Apple SD Gothic Neo";
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: transparent;
  box-sizing: content-box;
  cursor: pointer;
}

.wrap {
  flex-wrap: wrap;
}

.stretch {
  flex: 1 1 auto;
}

.pad {
  padding: 10px;
}

.logo {
  // padding: 13px 16px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.page-count {
  font-family: Poppins;
  text-align: start;
  font-size: 12px;
  color: #f6f7f8;
  margin: 16px 16px;
  height: 32px;
  line-height: 32px;
}
.rightToolbar {
  width: 240px;
  background: #383c47;
  color: white;
  .mybutton {
    @include flex-center();
    background: #545861;
    width: 36px;
    height: 36px;
    background: #545861;
    div {
      background-size: cover !important;
      width: 20px;
      height: 20px;
    }
    .select-btn {
      background: url("../../images/pages/select-btn.svg") no-repeat;
    }
    .square_draw_btn {
      background: url("../../images/pages/square_draw_btn.svg") no-repeat;
    }
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .selected {
    .select-btn {
      background: url("../../images/pages/select-btn-click.svg") no-repeat;
    }
  }
}

.mybutton.selected {
  background: #0ccea5;
}

.transform {
  padding-bottom: 16px;
  padding-top: 16px;
  &-title {
    text-align: left;
    padding-left: 16px;
    height: 32px;
    font-size: 13px;
    font-weight: 700;
    color: #f0f1f2;
    line-height: 32px;
  }
  &-axis {
    display: flex;
    flex-wrap: wrap;
    div {
      margin: 0 8px;
      width: 104px;
      position: relative;
      border-radius: 5px;
    }
    span {
      font-size: 14px;
      position: absolute;
      top: 5px;
      left: 8px;
      color: #9ea2a8;
    }
    input {
      font-size: 13px;
      width: 104px;
      height: 24px;
      background-color: #373c47;
      border: 0;
      outline: 0;
      padding-left: 30px;
      box-sizing: border-box;
      color: #9ea2a8;
      border-radius: 5px;
      &:hover {
        width: 104px;
        box-sizing: border-box;
        background-color: #555963;
      }
      &:focus {
        background-color: #252a35;
      }
    }
  }
}
.unit {
  color: #9ea2a8;
}
.percent {
  position: absolute;
  right: 8px;
  top: 12px;
  font-size: 14px;
}
.hover {
  display: none;
  padding-left: 29px;
  &:hover {
    outline: 1px solid #b3b8c0;
    box-sizing: border-box;
    width: calc(100% - 2px);
    margin-left: 1px;
  }
}
.registration {
  font-family: "Apple SD Gothic Neo";
  &-title {
    margin: 16px;
    font-size: 14px;
    color: "#eee";
  }
  &-list-item {
    &-link {
      padding: 4px 0;
      justify-content: space-between;
    }
    &-title {
      padding: 4px 4px 4px;
      &-input {
        span {
          font-size: 13px;
          align-items: center;
          width: 145px;
          height: 24px;
        }
        input {
          width: 130%;
        }
      }
      &:hover {
        outline: 1px solid #b3b8c0;
        box-sizing: border-box;
        width: calc(100% - 2px);
        margin-left: 1px;
        .icon-hover {
          display: flex;
        }
      }
    }
    &-title,
    &-link {
      display: flex;
      align-items: center;
      font-size: 12px;
      a {
        @include flex-center();
        background-color: #383d48;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-right: 16px;
      }
      input {
        height: 25px;
        font-size: 13px;
        background-color: transparent;
        border: 0;
        outline: 0;
        color: #e8ebed;
        box-sizing: border-box;

        &:focus {
          background-color: #fff;
          color: #383d48;
        }
      }
      .arrow {
        transition: 0.3s;
        transform: rotate(0deg);
        width: 15px;
        height: 15px;
        background: url("../../images/pages/arrow-left.svg") no-repeat center
          center;
        padding-right: 10px;
      }
    }

    .icon-hover {
      display: none;
      align-items: center;
      &:hover {
        display: flex;
      }
    }

    .icon-link {
      width: 12px;
      height: 12px;
      margin-right: 4px;
      background: url("../../images/pages/icon-link.svg") no-repeat center
        center;
      background-size: contain;
    }

    &-delect {
      @include flex-center();
      margin-left: 8px;
      width: 32px;
      height: 36px;
      background-color: #383d48;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .active {
    background-color: #252a35;
    .hover {
      display: block;
      align-items: center;
    }
    .arrow {
      transition: 0.3s;
      transform: rotate(90deg);
    }
  }
}
