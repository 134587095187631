/** @format */
.main {
  &-bottombar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 35px;
    background: #252a35;
    > div {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.3px;

      color: #9ea3aa;
    }
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-header {
  .toggle {
    margin-left: 12x;
    // padding: 12px 16px;
    color: #ffffff;
  }
}

.main-content-detect {
  display: flex;
  height: 100vh;
}
.sideBar {
  width: 200px;
  min-height: calc(100vh - 48px);
  max-height: calc(100% - 48px);
  background-color: #383d48;
  overflow-y: auto;

  .list {
    font-size: 16px;
    font-weight: 700;
    height: 44px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:first-child {
      margin-top: 16px;
    }
    .img {
      background-image: url("../../images/main/document.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-left: 16px;
      margin-right: 8px;
    }
  }
}

.MovableListTitle {
  font-size: 12px;
  color: #f6f7f8;
  padding: 15px;
  text-align: left;
}
.MovableList {
  cursor: pointer;
  img {
    width: 80px;
  }
  p {
    text-align: center;
    color: #9ea3aa;
    padding: 16px;
    font-size: 13px;
  }
  &:hover {
    img {
      border: 8px solid #b3b8c0;
      border-radius: 5px;
    }
  }
}
.MovableList.select {
  img {
    border: 8px solid #b3b8c0;
    border-radius: 5px;
  }
}

.div-active {
  span {
    width: 50px;
    height: 20px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 11px;
    margin-left: 16px;
    margin-bottom: 8px;
    line-height: 18px;
    display: block;
    text-align: center;
  }
  .icon-active-true {
    background-color: #111111;
  }
  .icon-active-false {
    background-color: #555963;
  }
}
