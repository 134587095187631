.toastContainer {
  display: flex;
  position: absolute;
  top: calc(100vh - 107px);
  left: calc(50% - 216px);
  width: 384px;
  height: 22px;
  // transform: translate(-50%, -50%);
  z-index: 100;
  background: rgba(13, 16, 26, 0.8);
  border-radius: 8px;
  padding: 16px 24px;
  align-items: center;
  animation: fadeInUp 1s;
  &-message {
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // letter-spacing: -0.35px;
    color: #f6f7f8;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
