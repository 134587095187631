/** @format */
.main {
  overflow: hidden;
  &-bottombar {
    // position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 0px 36px;
    // gap: 560px;

    // position: relative;
    // top: calc(100vh - 35px);
    width: 100vw;
    height: 35px;

    /* system/020 */

    background: #252a35;
    > div {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.3px;

      color: #9ea3aa;
    }
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #252a35;
  height: 60px;
  padding: 0 36px;
  .logo {
    // padding: 12px 16px;
    display: flex;
    justify-content: flex-start;
    border: 0;
    cursor: pointer;
  }
  .toggle {
    margin-left: 12x;
    padding: 12px 16px;
    color: #ffffff;
  }
  &-buttons {
    display: flex;
    border-radius: 4px;
  }
  .multiDownload {
    width: 90px;
    height: 32px;
    background: #383d48;
    border-radius: 5px;
    color: #e6e8eb;
    font-size: 14px;
    margin-right: 16px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #0bcea5;
      font-weight: bolder;
    }
  }
  .logOut {
    width: 65px;
    height: 32px;
    background: #383d48;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f6f7f8;
    font-size: 13px;
    // margin-right: 16px;
    cursor: pointer;
    &:hover {
      background-color: #464a53;
      font-weight: bolder;
    }
  }
  .modeSelect {
    display: flex;
    &-text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 500;
      width: 97px;
      height: 32px;
      line-height: 16px;
      color: #737780;
      font-size: 13px;
      background-color: #383d48;
    }
    &-text.active {
      color: #ffffff;
      background-color: #00b48e;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.16);
    }
    &-button {
      margin-left: 10px;
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
    }
    :first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    :last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .sortMode {
    display: flex;
    &-text {
      line-height: 48px;
      color: #9ea3aa;
      font-size: 30px;
    }
    &-text.active {
      color: #0bcea5;
    }
    &-button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.main-content {
  width: 100%;
  display: flex;
  height: calc(100vh - 95px);
  .main-wrapper {
    display: block;
    width: 100%;
    .menubar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #555963;
      height: 79px;
      // padding-bottom: 20px;
      border-bottom: 1px solid #4a4e58;

      &-toggle {
        display: flex;
        height: 28px;
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 14px;
        line-height: 20px;
        justify-content: space-between;

        &-mode {
          font-family: "Apple SD Gothic Neo";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #f6f7f8;
          text-align: center;
          letter-spacing: -0.35px;
        }
      }

      &-download {
        display: flex;
        float: right;
        padding-right: 36px;
        button {
          width: 95px;
          height: 32px;
          background-color: #383d48;
          border-radius: 4px;
          font-family: "Apple SD Gothic Neo";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.4px;
          color: #737780;
          // display: flex;
          justify-content: center;
          align-items: center;
          cursor: default;
        }
        .printBtn {
          margin-right: 8px;
          display: flex;
          align-items: center;
          color: #ffffff;
          cursor: default;
          > img {
            margin-right: 4px;
          }
          &.active {
            background-color: #6d59e8;
            cursor: pointer;
          }
        }
        .downloadBtn {
          width: 95px;
          height: 32px;
          background-color: #00b48e;
          border-radius: 4px;
          font-family: "Apple SD Gothic Neo";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.4px;
          color: #ffffff;
          cursor: pointer;
          &:hover {
            background-color: #0bcea5;
          }
        }
        .none {
          display: none;
        }
        .addBtn {
          font-family: "Apple SD Gothic Neo";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.4px;
          color: #f6f7f8;
          margin-right: 8px;
          > img {
            margin-right: 6px;
          }
          &:hover {
            background-color: #464a53;
          }
        }
      }
    }

    .toggle-btn {
      // display: flex;
      // align-items: center;
      > div {
        display: flex;
        align-items: center;
        height: 100%;
        // height: 16px;
        & > button {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 28px;
          height: 28px;
          margin-right: 2px;
          > img {
            display: none;
            position: absolute;
            top: 30px;
            left: -2px;
          }
        }
        :first-child > img {
          // position: absolute;
          // left: 20px;
          left: -1px;
        }
        // :nth-child(3) > img {
        //   // position: relative;
        //   left: -55px;
        // }
        :nth-child(4) {
          top: 8px;
        }
        :nth-child(3) > img {
          // position: relative;
          left: -53px;
        }
        :nth-child(4)::before {
          content: "";
          // position: absolute;
          top: 8px;
          left: 0;
          display: block;
          width: 1px;
          height: 16px;
          background-color: #737780;
          margin: 0 8px;
        }
        :hover > img {
          display: inline;
          z-index: 100;
        }
      }
    }
  }
}
.sideBar {
  width: 200px;
  min-height: calc(100vh - 28px);
  max-height: calc(100% - 28px);
  background-color: #383d48;
  overflow-y: auto;

  .list {
    font-size: 16px;
    font-weight: 700;
    height: 44px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:first-child {
      margin-top: 16px;
    }
    .img {
      background-image: url("../../images/main/document.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-left: 16px;
      margin-right: 8px;
    }
  }
}

input[type="file"] {
  display: none;
}
.container {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: calc(100vw - 36px);
  height: calc(100vh - 175px);
  background-color: #545861;
  padding: 2px 18px 0 18px;
  overflow-y: auto;
  &-wrapper {
    width: calc(100vw - 36px);
    height: calc(100% + 60px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    // padding-bottom: 60px;
  }
  .createFile {
    cursor: pointer;
    // position: relative;
    background-color: #383d48;
    &:hover {
      background-color: #464a53;
    }
  }
  &-card {
    width: 203px;
    height: 317px;
    box-sizing: border-box;
    background-color: #555963;
    border-radius: 12px;
    margin: 13px;
    position: relative;
    border: 2.5px solid #555963;
    .react-pdf__Page {
      // margin-bottom: 10px;
      width: 100%;
      height: 261.5px;
      object-fit: cover;
      // border-radius: 12px;
    }
    .react-pdf__Page__canvas {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      border-radius: 12px 12px 0 0;
    }

    &:hover {
      border: 2.5px solid rgba(255, 255, 255, 0.5);
      filter: drop-shadow(12px 12px 24px rgba(0, 0, 0, 0.2));
      .linkOpenHover {
        display: block;
      }
    }

    &:hover .container-card-shadow {
      background: rgba(13, 16, 26, 0.8);
      cursor: pointer;
      filter: drop-shadow(12px 12px 24px rgba(0, 0, 0, 0.2));
    }
    & > div > img {
      object-fit: cover;
      border-radius: 12px 12px 0 0;
    }
    .linkOpenHover {
      display: none;
    }
    &-shadow {
      position: absolute;
      width: 198px;
      height: 261.5px;
      top: 0;
      left: 0;
      background-color: #0d101a;
      opacity: 0;
      border-radius: 12px 12px 0px 0px;

      .iocnGraup {
        display: flex;
        height: 40px;
        align-items: center;
        margin-top: 20px;
        &-btn {
          :first-child > button > img {
            height: 100%;
            border-right: 1px solid black;
          }
        }
        div {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: end;

          > button {
            > .updateHover {
              border-radius: 8px 0px 0px 8px;
              fill: #9ea3aa;
              &:hover {
                fill: #00b48e;
              }
              margin-right: 16px;
            }
          }
        }
        .description {
          background: #0bcea5;
          &:hover {
            background: #0c4944;
          }
          .photo {
            width: 24px;
            height: 24px;
            background: url("../../images/main/photo.svg") no-repeat;
            background-size: contain;
            margin: 0;
            border-radius: 0;
          }
        }
        .delete {
          background: #9ea2a8;
          &:hover {
            background: #383c45;
          }
          .trash {
            width: 22px;
            height: 22px;
            background: url("../../images/main/trash.svg") no-repeat;
            background-size: contain;
            margin: 0;
            border-radius: 0;
          }
        }
      }
    }
    &-card:not(:first-child) {
      background-color: #555963;
    }
    .plus {
      width: 72px;
      height: 72px;
      // position: relative;
      &::before,
      &::after {
        position: absolute;
        top: 117.5px;
        left: 99.5px;
        content: "";
        height: 72px;
        width: 2px;
        background-color: #ffffff;
        cursor: pointer;
      }
      &::before {
        transform: rotate(90deg);
      }
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      width: 198px;
      height: 50.5px;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 0 8px;
      background-color: #383d48;
      letter-spacing: -0.35px;
      color: #ffffff;
      border-radius: 0px 0px 12px 12px;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      > :first-child {
        // width: 129px;
        // height: 20px;
        margin-left: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      > :last-child {
        height: 32px;
        > .linkOpenHover {
          cursor: pointer;
          fill: #9ea3aa;
          &:hover {
            fill: #00b48e;
          }
        }
      }
    }
  }
  &-card {
    &:hover .container-card-shadow {
      opacity: 1;
      cursor: pointer;
    }

    &-shadow {
      &-description {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.325px;
        color: #b3b8c0;
        position: absolute;
        margin-left: 16px;
        bottom: 16px;
      }
      .iocnGraup {
        &-btn {
          :first-child > button > img {
            height: 100%;
            border-right: 1px solid black;
          }
        }
      }
    }
  }
  &-card.active {
    width: 203px;
    height: 317px;
    border: 2.5px solid #0bcea5;
    filter: drop-shadow(12px 12px 24px rgba(0, 0, 0, 0.2));
    // margin: 16px;
  }
  // &-card.cert {
  //   border: 5px solid #0bcea5;
  // }
}

.detectWrapper {
  background-color: #555963;
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
}

.cardWrapper {
  width: 100%;
  :hover {
    background-color: #414650;
  }
}

.progressBar {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 32px);
  margin-top: 36px;
  margin-bottom: 20px;
  padding: 0 16px;
  &-progress {
    width: 100%;
    display: flex;
    > div {
      display: flex;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.35px;
      margin-right: 4px;
      color: #b3b8c0;
      &:first-child {
        margin-right: 4px;
      }
      > svg {
        margin-right: 8px;
      }
    }
  }

  &-tooltip {
    width: 152px;
    height: 32px;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.35px;
    color: #f6f7f8;
    position: relative;

    > div {
      display: flex;
      align-items: center;
      // position: relative;

      .alphaTooltip {
        position: absolute;
        display: none;
        left: -91px;
        top: 30px;
        z-index: 10000;
      }
      .tooltipImg {
        margin-right: 8px;
      }
    }
    :hover .alphaTooltip {
      display: block;
    }
  }
}

.container-detect.result {
  width: calc(100vw - 423px);
}
.container-detect {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  width: calc(100vw - 72px);
  height: 100vh;

  background-color: #545861;
  padding: 0 36px;
  overflow-y: auto;
  .createFile {
    cursor: pointer;
    z-index: 1000;
  }
  .createFile.active {
    background-color: #252a35;
  }
  &-card {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    height: calc(100vh - 276px);
    justify-content: center;
    align-items: center;
    background-color: #383d48;
    border-radius: 16px;
    position: relative;
    // border: 2px solid #0bcea5;
    // object-fit: cover;
    position: relative;

    &-img2 {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    // &-card.cert {
    //   border: 5px solid #0bcea5;
    // }

    // .detectImg {
    //   object-fit: cover;
    //   width: auto;
    //   height: 100%;
    // }

    &-text1 {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: -0.75px;
      color: #f6f7f8;
      margin-top: 40px;
      margin-bottom: 16px;
    }

    &-text2 {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.5px;
      text-decoration-line: underline;

      color: #b3b8c0;
    }

    &:hover .container-detect-card-shadow {
      opacity: 1;
      cursor: pointer;
    }
    &-shadow {
      position: absolute;
      width: 100%;
      height: calc(100vh - 276px);
      top: 0;
      left: 0;
      background: rgba(13, 16, 26, 0.6);
      border-radius: 16px;
      opacity: 0;

      .iocnGraup {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 12px;
        div {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          margin: 4px 16px;
        }
        .description {
          background: #9ea2a8;
          &:hover {
            background: #0bcea5;
          }
          .photo {
            width: 24px;
            height: 24px;
            background: url("../../images/main/photo.svg") no-repeat;
            background-size: contain;
            margin: 0;
            border-radius: 0;
          }
        }
        .delete {
          background: #9ea2a8;
          &:hover {
            background: #0bcea5;
          }
          .trash {
            width: 22px;
            height: 22px;
            background: url("../../images/main/trash.svg") no-repeat;
            background-size: contain;
            margin: 0;
            border-radius: 0;
          }
        }
      }
    }
    .plus {
      width: 72px;
      height: 72px;
      &::before,
      &::after {
        position: absolute;
        top: 282px;
        left: 280px;
        content: "";
        height: 72px;
        width: 2px;
        background-color: #ffffff;
        cursor: pointer;
      }
      &::before {
        transform: rotate(90deg);
      }
    }
    &-title {
      display: flex;
      align-items: center;
      width: 200px;
      height: 40px;
      font-size: 14px;
      flex-grow: 1;
      font-family: "Apple SD Gothic Neo";
      input {
        width: 184px;
        height: 32px;
        padding-left: 8px;
        border: 0;
        margin: 4px 8px;
        background-color: transparent;
        color: #fff;
        box-sizing: border-box;
        font-weight: 700;
        &::placeholder {
          color: #b3b8c0;
          font-weight: 400;
        }
      }
      input:focus-visible {
        border: 2px solid #3390ee;
        background-color: #252a35;
      }
      span {
        color: #fff;
        font-weight: 700;
        padding-left: 16px;
      }
    }
  }
  &-button {
    margin-top: 36px;
    display: flex;
    justify-content: end;
    > button {
      width: 208px;
      height: 56px;
      left: 1196px;
      top: 932px;
      background-color: #00b48e;
      border-radius: 8px;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.4px;
      color: #ffffff;
      /* white */
      &:hover {
        background-color: #0bcea5;
      }

      cursor: pointer;
      &:disabled {
        background-color: #383d48;
        color: #737780;
      }
    }
  }
  &-result {
    width: 550px;
    height: 198px;
    background-color: #383c45;
    border-radius: 10px;
    margin: 20px;
    position: relative;
    &-button {
      background-color: #0bcea5;
      z-index: 100;
    }
    &-guide {
      padding: 35px 15px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
    }
    &-success {
      color: #0bcea5;
      padding: 25px 25px;
      font-weight: 700;
    }
    &-fail {
      color: #ff0000;
      padding: 40px 15px;
      font-weight: 700;
    }
    &-textL {
      margin-bottom: 30px;
      font-size: 30px;
      text-align: center;
    }
    &-text {
      margin-bottom: 10px;
      text-align: center;
    }
    &-content {
      display: flex;
    }
    &-title {
      background-color: #0bcea5;
      color: #ffffff;
      margin-bottom: 10px;
      margin-right: 10px;
      width: 150px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 5px;
    }
    &-textS {
      line-height: 25px;
      padding-bottom: 10px;
      width: 430px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-url {
      margin-bottom: -5px;
      margin-left: 10px;
    }
  }
  &-log {
    width: 550px;
    height: 348px;
    background-color: #000000;
    border-radius: 10px;
    margin: 20px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    overflow-y: auto;
    border &-text {
      color: #0bcea5;
      margin-left: 15px;
      margin-top: 10px;
    }
    &-text.success {
      color: #0bcea5;
      margin-left: 15px;
      margin-top: 10px;
    }
    &-text.fail {
      color: red;
      margin-left: 15px;
      margin-top: 10px;
    }
  }
}
.MovableListTitle {
  font-size: 12px;
  color: #f6f7f8;
  padding: 15px;
  text-align: left;
}
.MovableList {
  cursor: pointer;
  img {
    width: 80px;
  }
  p {
    text-align: center;
    color: #9ea3aa;
    padding: 16px;
    font-size: 13px;
  }
  &:hover {
    img {
      border: 8px solid #b3b8c0;
      border-radius: 5px;
    }
  }
}
.MovableList.select {
  img {
    border: 8px solid #b3b8c0;
    border-radius: 5px;
  }
}

.div-active {
  span {
    width: 50px;
    height: 20px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 11px;
    margin-left: 16px;
    margin-bottom: 8px;
    line-height: 18px;
    display: block;
    text-align: center;
  }
  .icon-active-true {
    background-color: #111111;
  }
  .icon-active-false {
    background-color: #555963;
  }
}

.container-result {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 279px;
  height: calc(100% - 76px);
  // height: 964px;
  background-color: #383d48;
  padding: 40px 36px 36px 36px;
  &-img {
    &-title {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.4px;
      color: #f6f7f8;
      margin-bottom: 20px;
    }
    &-box {
      background-color: #252a35;
      border-radius: 12px;
      &-image {
        margin: 16px;
        width: calc(100% - 32px);
        height: calc(348px - 32px);
        display: flex;
        justify-content: center;

        & > img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      &-description {
        &-first {
          padding: 24px 16px 16px 16px;

          :first-child {
            font-family: "Apple SD Gothic Neo";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -0.5px;
            color: #f6f7f8;
            margin-bottom: 4px;

            // height: 32px;
            margin-right: 10px;
            display: flex;
          }

          :last-child {
            font-family: "Apple SD Gothic Neo";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: -0.3px;
            color: #9ea3aa;
          }
        }
        &-second {
          padding: 16px;
          border-top: 1px solid #414650;
          border-bottom: 1px solid #414650;
          > div:not(:last-child) {
            margin-bottom: 8px;
          }
          &-title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #9ea3aa;
          }
          &-content {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #f6f7f8;
          }
        }
        &-third {
          padding: 16px;
          display: flex;
          align-items: center;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          // width: 100%;

          :first-child {
            color: #9ea3aa;
            white-space: pre;
            margin-right: 25px;
          }
          &-link {
            width: 167px;
            color: #b3b8c0;
            text-overflow: ellipsis;
          }
          :last-child {
            margin-left: 8px;
          }
        }
      }
    }
  }

  &-download {
    width: 279px;
    height: 56px;
    background: #00b48e;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    > button {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.4px;
      color: #f6f7f8;
    }
    &:hover {
      background-color: #0bcea5;
    }
  }
}

.askBtn {
  bottom: 80px;
  right: 36px;
  z-index: 100;
  position: absolute;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    padding: 0 20px;
    background-color: #252a35;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 100px;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #ffffff;
    &:hover {
      background-color: #464a53;
      box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.16);
    }
  }
}

.bigcert {
  // display: flex;
  position: absolute;
  top: 36px;
  left: 36px;
}

.colorModePopup {
  position: fixed;
  top: 0;
  left: 0;
  padding: 80px;
  width: calc((100vw - 160px));
  height: calc((100vh - 160px));
  background-color: rgba(13, 16, 26, 0.9);
  &-back {
    > img {
      margin-right: 16px;
      cursor: pointer;
    }
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 56px;
    letter-spacing: -0.9px;
    color: #b3b8c0;
    display: flex;
    align-items: center;
  }
  &-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-desc {
      > :first-child {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        letter-spacing: -1px;
        color: #ffffff;
      }
      > :last-child {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.4px;
        color: #b3b8c0;
        margin-top: 12px;
      }
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #b3b8c0;
      margin-top: 40px;

      > div {
        width: 251px;
        background: #383d48;
        border-radius: 12px;
        padding: 32px;
        cursor: pointer;
        > :first-child {
          font-family: "Apple SD Gothic Neo";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 44px;
          letter-spacing: -0.7px;
          color: #ffffff;
        }
        > :last-child {
          font-family: "Apple SD Gothic Neo";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.35px;

          margin-top: 12px;
        }
      }
      > :first-child {
        margin-right: 36px;
      }
      > :hover {
        background-color: #00b48e;
        color: #ffffff;
      }
    }
  }
}

.emailFormBackDrop {
  position: fixed;
  bottom: 792px;
  right: 590px;
  z-index: 1000;
}

.emailFomrContainer {
  position: absolute;
  width: 560px;
  height: 640px;
  // left: 844px;
  // top: 232px;
  overflow-y: scroll;
  box-sizing: border-box;
  /* system/020 */
  padding: 0 36px;
  background: #252a35;
  border-radius: 12px;
}
