/** @format */
// #root {
//   overflow: scroll;
//   width: 100%;
//   // height: 100%;
//   overflow: hidden;
// }

.register {
  font-family: "Apple SD Gothic Neo";
  background-color: #f0f1f2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    display: block;
    margin-bottom: 20px;
  }

  // overflow: hidden;
  @media (min-height: 1037px) {
    height: 100vh;
  }
  div {
    font-family: "Apple SD Gothic Neo";
  }

  &-error {
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.3px;
    color: #ef284c;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    & > img {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
  &-container {
    width: 700px;
    // height: 100%;
    background: #ffffff;
    padding-bottom: 36px;
    @media (min-height: 1037px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
    }
  }
  &-logo {
    // width: calc(100% - 40px);
    height: 80px;
    display: flex;
    align-items: center;

    // background: url("../../images/login/login-logo.svg") no-repeat;
    background-size: contain;
    // margin: 27px 40px;
    padding-left: 40px;
    margin-bottom: 60px;
    // padding-top: 27.5px;
  }
  &-content {
    // height: calc(100% - 105px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    > img {
      margin-right: 4px;
    }
  }
  &-title {
    width: 440px;
    height: 46px;
    font-weight: 700;
    font-size: 36px;
    // margin-top: 10px;
    margin-bottom: 16px;
    line-height: 46px;
    letter-spacing: -0.01em;
    color: #111111;
  }
  &-description {
    width: 440px;
    height: 40px;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 40px;
    // margin-top: 16px;
    letter-spacing: -0.35px;

    color: #555963;
  }

  &-form {
    display: flex;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // margin-bottom: 30px;
    // & > div:hover .login-icon {
    // 	display: block;
    // }
    > label {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 10px;
      font-family: "Apple SD Gothic Neo Bold";
      display: block;
      > input {
        display: block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
      }
    }
    &-email {
      position: relative;
      margin-bottom: 16px;
      // > input {
      :focus {
        border: 1px solid #00b48e;
      }
      // }
      :last-child {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.3px;
        color: #ef284c;
        top: 17px;
      }
    }

    &-email.error {
      input {
        border: 1px solid #ef284c;
        background: rgba(239, 40, 76, 0.08);
        margin-bottom: 8px;
      }
      margin-bottom: 16px;
    }
    &-password {
      position: relative;
      margin-bottom: 20px;
      :focus {
        border: 1px solid #00b48e;
      }
      :last-child {
        // margin-top: 8px;
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.3px;
        color: #ef284c;
        height: 24px;
      }
    }

    &-password.error {
      input {
        border: 1px solid #ef284c;
        background: rgba(239, 40, 76, 0.08);
        margin-bottom: 8px;
      }
    }

    &-gender {
      display: flex;
      gap: 10px;
      &-btn {
        width: 50%;
        background-color: #f0f1f2;
        // border: 1px solid #00b48e;
        height: 56px;
        border-radius: 10px;
        color: #111111;
        font-size: 16px;
        font-family: "Apple SD Gothic Neo Bold";
        // &:hover {
        //   background-color: #0bcea5;
        // }
      }
      &-btn.active {
        background-color: #111111;
        color: #fff;
      }
    }
  }

  &-button {
    margin-top: 30px;
    margin-bottom: 32px;
    &:disabled {
      background-color: #f0f1f2;
    }
  }

  &-save {
    margin-bottom: 32px;
    .checkbox {
      width: 63px;
      height: 20px;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.35px;
      color: #555963;
      align-items: center;
      gap: 60px;
      text-align: center;

      input {
        display: inline;
        appearance: none;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border: 1px solid #e6e8eb;
        border-radius: 4px;
        background-color: #ffffff;
        margin-right: 7px;
        &:checked {
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z' fill='%230BCEA5'/%3E%3Cpath d='M6.49749 12.995C6.43184 12.9958 6.36676 12.9828 6.30642 12.957C6.24608 12.9312 6.19182 12.893 6.14713 12.845L4.14512 10.845C4.0522 10.7521 4 10.6263 4 10.495C4 10.3637 4.0522 10.2378 4.14512 10.145C4.23804 10.0521 4.36407 10 4.49547 10C4.62688 10 4.75291 10.0521 4.84583 10.145L6.49749 11.785L13.1542 5.14497C13.2471 5.05215 13.3731 5 13.5045 5C13.6359 5 13.762 5.05215 13.8549 5.14497C13.9478 5.2378 14 5.3637 14 5.49498C14 5.62625 13.9478 5.75215 13.8549 5.84498L6.84784 12.845C6.80315 12.893 6.74889 12.9312 6.68855 12.957C6.62821 12.9828 6.56313 12.9958 6.49749 12.995Z' fill='white' stroke='white' stroke-width='0.5'/%3E%3C/svg%3E%0A");
          background-size: 100% 100%;
          background-position: 50%;
          background-repeat: no-repeat;
          background-color: #0bcea5;
          border: 1px solid #0bcea5;
        }
      }
    }
  }

  &-register {
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.32px;
    color: #555963;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    > a {
      color: #555963;
    }
    > :first-child {
      display: flex;
      > div {
        cursor: pointer;
      }
      > :first-child {
        margin-right: 20px;
      }
    }
    > :last-child {
      cursor: pointer;
    }
  }

  &-guide {
    position: relative;
    display: flex;
    width: 440px;
    height: 26px;
    // align-items: center;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    justify-content: center;
    letter-spacing: -0.45px;
    // padding-top: 4px;
    color: #252a35;
    // text-align: center;
    margin-bottom: 20px;
    button {
      width: 16px;
      height: 26px;
      margin-left: 6px;
    }
    &-tooltip {
      margin-left: 6px;
      :hover + .tooltipbar {
        display: inline;
      }
      > img {
        margin-top: 4px;
      }
    }
  }
  .error-message {
    color: #ef284c;
    font-size: 12px;
    margin-top: 8px;
    width: 100%;
    margin-left: 0;
    align-items: flex-start;
    font-family: Apple SD Gothic Neo;
    letter-spacing: -0.3px;
    font-weight: normal;
  }
  input {
    width: 440px;
    height: 52px;
    padding-left: 16px;
    background: #f0f1f2;
    border: 0;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 16px;
    // margin-top: 12px;
    color: #303538;
    font-weight: bold;
    &::placeholder {
      color: #9ea3aa;
      font-size: 16px;
      font-family: Poppins;
      font-weight: 400;
    }
  }
  &-button {
    width: 440px;
    height: 56px;
    background: #111111;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-family: "Apple SD Gothic Neo Bold";
  }
  &-action {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
    color: #79828a;
    font-size: 14px;
    a {
      cursor: pointer;
      width: 194px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00b48e;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }
    a:first-child {
      margin-right: 12px;
      background: #e6e8eb;
      color: #383d48;
    }
    .line {
      color: #e8e8e8;
    }
  }
}

.login-icon {
  position: absolute;
  right: 16px;
  top: 14px;
  display: block;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}

.tooltipbar {
  display: none;
  top: -190px;
  left: 320px;
  z-index: 10;
  position: absolute;
}
